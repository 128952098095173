/* .fonImgBckP{
  position:relative;
  z-index:2;
  top:0;
  background-image :
  /* url("./../img/BlueSpot.png"), */
  /* url("./../img/YellowSpot.png"),  *
  url("./../img/ManchaRosa.png");
  background-position: 
  /* top  right, */
  /* top 1600px left,  *
  bottom 2% right;
  background-repeat:no-repeat;
  background-size: 70%;
 } */
 
#CarruselEmpresaWebLp.rec-dot{
    width: 12px;
    height: 12px;
    background: rgba(172, 46, 91, 0.54);
    border: none;
    border-radius: none;
 
   }
   
   .rec-dot-carousel-wrapper{
   background: red;
   }
   #CarruselEmpresaWebLp.rec-dot:hover, #CarruselEmpresaWebLp.rec-dot:active, #CarruselEmpresaWebLp.rec-dot:focus  {
     background: #AC2E5B;
     border: none;
     border-radius: none;
   }

  
 
   .Gradiante1{
   
     display: flex;
     align-items: center;
     flex-direction: column;
     background-image :url("./../img/BlueSpot.png");
     background-size: 60% auto , cover;
     background-position: top  right;
     background-repeat:no-repeat; 
     
     
      /*   , linear-gradient(to bottom, rgb(255, 255, 255, 1) , rgb(	181, 225, 246, 0.55 ), rgb(	209, 209, 247, .60 ) );  */
    
    
     
   }
 
   .Gradiante2{
     display: flex;
     align-items: center;
     flex-direction: column;
    
     /* background: #D1D1F7;
     background: -moz-linear-gradient(top, #D1D1F7 0%, #E1F3FB 100%);
     background: -webkit-linear-gradient(top, #D1D1F7 0%, #E1F3FB 100%); */
     /* background: linear-gradient(to bottom, rgb(	209, 209, 247, 0.55 ), rgb(	225, 243, 251, 0.55 ));  */
    
   }
 
 

   .Gradiante3{
     display: flex;
     align-items: center;
     flex-direction: column;
      background-image :url("./../img/YellowSpot.png");
     background-position:  left;
     background-repeat:no-repeat;
     background-size: 54% auto , cover; 
     /* linear-gradient(to bottom, rgb(	225, 243, 251, 0.55 ), rgb(251, 230, 209, 0.55 ), rgb(	253, 201, 209, 0.55)) */
   
   }
  
 
    .Gradiante4{
     display: flex;
     align-items: center;
     flex-direction: column;

 
   
   } 

 
   .Gradiante5  {
     display: flex;
     align-items: center;
     flex-direction: column;
     background-position:  top 5%   right;
     background-repeat:no-repeat;
     background-image :url("./../img/ManchaRosa.png"), linear-gradient(0deg, rgba(255,253,254,1) 17%, rgba(254,234,241,1) 86%); ;
     background-size: 54% auto , cover;
    
   } 
   /* */
 
   .a1 {
     color: black;
     text-decoration: none;
     display:flex;
     flex-wrap: nowrap;
     position: relative;
     list-style: none;
     font-size: 16px;
     cursor: pointer;
    }
 
   .a1:hover {
     color: black !important;
   }


   .a2 {
    color: black;
     text-decoration: none;
     display:flex;
     flex-wrap: nowrap;
     position: relative;
     list-style: none;
     font-size: 16px;
     cursor: pointer;

   }

  .a2:hover {
    color: black !important;
  }

  .a3 {
    color: black;
     text-decoration: none;
     display:flex;
     flex-wrap: nowrap;
     position: relative;
     list-style: none;
     font-size: 16px;
     cursor: pointer;

   }

  .a3:hover {
    color: black !important;
  }

  .a4 {
    color: black;
     text-decoration: none;
     display:flex;
     flex-wrap: nowrap;
     position: relative;
     list-style: none;
     font-size: 16px;
     cursor: pointer;

   }

  .a4:hover {
    color: black !important;
  }

  .a5 {
    color: black;
    text-decoration: none;
    display:flex;
    flex-wrap: nowrap;
    position: relative;
    list-style: none;
    font-size: 16px;
    cursor: pointer;

   }

  .a5:hover {
    color: black !important;
  }





 
 
   .cardWo1 {
     width: 100%;
     display: flex;
     background-image: url("./../img/WavesPink.svg");
     background-position: right ;
     background-size: 19rem;
     background-repeat: no-repeat;
     border-radius: 16px;
     background-position-x: 107%;
   }
 

   .cardWo2 {
     width: 100%;
     display: flex;
     flex-direction: row;
     background-image: url("../../Assets/img/WavesPr.svg");
     background-size: 19rem;
     background-position: right ;
     background-repeat: no-repeat;
     border-radius: 16px;
     background-position-x: 107%;
     
   }
 
   
   .cardWo3{
     width: 100%;
     display: flex;
     background-image: url("./../img/Waves_PlandeAhorro.svg");
     background-size: 19rem;
     background-position: right;
     background-repeat: no-repeat;
     border-radius: 16px;
     background-position-x: 107%;
   }


   .iconShare{
  
  }
  
  .iconShare div:last-child {
    display: none;  
  }
  .iconSahre:hover div:last-child {
    display: block;  
  }
  .iconShare:hover div:first-child {
    display: none;  
  }

   .iconSocial{
  
  }
  
  .iconSocial div:last-child {
    display: none;  
  }
  .iconSocial:hover div:last-child {
    display: block;  
  }
  .iconSocial:hover div:first-child {
    display: none;  
  }



  .Container1{
    position:relative;
    top:50px;
    bottom:-50px;
    z-index: 0;
  
   }
  
   .ContainerTitleShare1{
     position:absolute;
     top:-50px;
     z-index: 0;
   
    }
   
   .ItemShare1{
     position:absolute;
   
     z-index: 2;
  }
  
  .Container2{
   position:relative;
   top:-50;
   bottom:-50px;
  }
  
  .ContainerTitleShare2{
    position:absolute;
    top:-50px;
    z-index: 0;
   }
  
  .ItemShare2 {
    position:absolute;
  
    z-index: 2;
  }
  
  
  .Container3{
  position:relative;
  top:50px;
  bottom:-50px;
  }
  
  .ContainerTitleShare3{
   position:absolute;
   top:-55px;
   z-index: 0;
  }
  
  .ItemShare3 {
   position:absolute;
  
   z-index: 2;
  }
  
  
  
  
  
  .Container4{
  position:relative;
  top:50px;
  bottom:-50px;
  }
  
  .ContainerTitleShare4{
   position:absolute;
   top:-50px;
   z-index: 0;
  }
  
  .ItemShare4 {
   position:absolute;
  
   z-index: 2;
  }
  



.containerSections {




}


@media (max-width: 340px) {
  .cardWo1, .cardWo2, .cardWo3 {
    background-position-x: -88%!important;
  }
}






 @media (max-width: 700px) {
  .widthmobile
  {
    width: 100%;

  }
  
  .cardWo1, .cardWo2, .cardWo3 {
    background-position-x: 136%;
  }


  


  .webCarrousel{
    display: none!important;
  }
  .mobileCarrousel{
    display: block!important;
  }

  #carouselMedios>.carousel-root >.carousel.carousel-slider>.slider-wrapper
  {
    height: 290px!important;
  }

  #carouselMedios>.carousel-root >.carousel.carousel-slider> .slider-wrapper > .slider
  {
    width: 80%!important;
  }

}

@media (max-width: 415px) and (min-width: 390px) {
  #carouselMedios>.carousel-root >.carousel.carousel-slider> .slider-wrapper > .slider
  {
    width: 75%!important;
  }
}
@media (max-width: 389px) and (min-width: 375px) {
  #carouselMedios>.carousel-root >.carousel.carousel-slider> .slider-wrapper > .slider
  {
    width: 80%!important;
  }
}

@media (max-width: 374px) and (min-width: 357px) {
  #carouselMedios>.carousel-root >.carousel.carousel-slider> .slider-wrapper > .slider
  {
    width: 83%!important;
  }
}

@media (max-width: 356px) and (min-width: 341px) {
  #carouselMedios>.carousel-root >.carousel.carousel-slider> .slider-wrapper > .slider
  {
    width: 88%!important;
  }
}

@media (max-width: 340px) {
  #carouselMedios>.carousel-root >.carousel.carousel-slider> .slider-wrapper > .slider
  {
    width: 90%!important;
  }
}




.sectionBackgroundChange {
  display: flex;
  align-items: center;
  flex-direction: column;
  &[data-bg=" "] { color: #333; 
  
  
  
                    }
}
/* Conatiner padre de dots indicadores Carousel */
.control-dots{
  display:flex;
  flex-direction:row;
  justify-content: center;
}

.MuiPaper-elevation1{

  box-shadow:none;
}




.MuiAccordion-root:before {
  height: 0px!important;
}
  
  








 






.webCarrousel{
  display: block;
}
.mobileCarrousel{
  display: none;
}


  .MuiAccordion-root.Mui-expanded
  {
    margin: auto!important;
  }


  .CrearCuentaBtn{
    display: flex;
    width: 100%;
    index-z:20;
    position:fixed;
    bottom: 0px;
   
    height:80px;
    align-items:center;
    padding-left:2%;
    padding-right:2%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 35.94%);
  }
  

  @media (min-width: 768px) {

    .CrearCuentaBtn{
      display: none;
    
    
    }
  
   }

    .GradiBanMobile {

   
   
} 



@media (max-width: 390px) {

  .GradiBanMobile {
    background:  linear-gradient(to bottom, rgb(255, 255, 255, 1) , rgb(	181, 225, 246, 0.55 ), rgb(	209, 209, 247, .60 )) ;
 height:200px;
   
}
.BackgrounMobile{
  background: rgba(250, 250, 250, 0.8);
  border-radius: 30px;
  width: 40px;
  height: 188px;
  left: 351px;
  top: 563px;
}
}
@media (max-width: 425px) {

  .GradiBanMobile {
    background:  linear-gradient(to bottom, rgb(255, 255, 255, 1) , rgb(	181, 225, 246, 0.55 ), rgb(	209, 209, 247, .60 )) ;
    height:350px;
   
}
.BackgrounMobile{
  background: rgba(250, 250, 250, 0.8);
  border-radius: 30px;
  width: 40px;
  height: 188px;
  left: 351px;
  top: 563px;
}
}


@media (max-width: 768px) {

  .GradiBanMobile {
    background:  linear-gradient(to bottom, rgb(255, 255, 255, 1) , rgb(	181, 225, 246, 0.55 ), rgb(	209, 209, 247, .60 )) ;
    height:450px;
   
}
.BackgrounMobile{
  background: rgba(250, 250, 250, 0.8);
  border-radius: 30px;
  width: 40px;
  height: 188px;
  left: 351px;
  top: 563px;
}

}

@media (max-width: 1024px) {

  .GradiBanMobile {

    height:650px;
   
}
.BackgrounMobile{
  background: rgba(250, 250, 250, 0.8);
  border-radius: 30px;
  width: 40px;
  height: 188px;
  left: 351px;
  top: 563px;
}
 }

.backdrop-blur {
  background-color: rgba(255, 255, 255, .5);
  -webkit-backdrop-filter: blur(2em);
  backdrop-filter: blur(2em);
}