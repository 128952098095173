.rec .rec-dot {
  background-color: #CDCDCD;
  box-shadow: 0 0 1px 3px #CDCDCD;
  width: 6px !important;
  height: 6px !important;
}
.rec .rec-dot_active{
  padding: 0px;
  background-color: #A6A6A6;  
  box-shadow: 0 0 1px 3px #A6A6A6;
  width: 6px !important;
  height: 6px !important;
}
.rec .rec-dot_active:focus{
  padding: 0px;
  background-color: #A6A6A6;  
  box-shadow: 0 0 1px 3px #A6A6A6;
  width: 6px !important;
  height: 6px !important;
}

.rec-carousel{
  height: 750px;
}
#CarruselProductos  .rec .rec-item-wrapper {

      width: 355px !important;
} 

#CarruselEmpresasContentHomeFast > div > .rec-carousel
{
   height: 635px !important;
}
#CarruselEmpresasContentLogrosCC > div > .rec-carousel
{
  height: 465px !important
}
