.MuiInputBase-input
{
	text-align: left;
	color: #000!important;
	font-size: 16px!important;
	font-family: 'Roboto', sans-serif !important;
}

.MuiInputBase-input#monthlyAmount
{
	text-align: left;
	color: rgb(236, 64, 122)!important;
	font-size: 16px!important;
	font-family: 'Roboto', sans-serif !important;
}

.MuiInputLabel-formControl
{
	width: 100%!important;
}

.MuiInput-underline:before
{
	border-bottom: 1px solid #d7e1e6!important;
	font-family: 'DM Sans'!important;
}

.MuiFormLabel-asterisk, .MuiInputLabel-asterisk
{
	display: none!important;
}

.intermediateWidth
{
	width: 100%!important;
	margin: auto!important;
}

.displaygrid
{
	display: grid;
}

@media (max-width: 600px) {
	.intermediateWidth
	{
		width: 100%;
	}
	.MuiInputLabel-shrink {
		font-size: 15px!important;
		letter-spacing: -.00020em!important;
	}
	.MuiFormLabel-root
	{
		color:#000!important;
		font-size: 15px!important;
		width: 127%!important;
	}
}
@media (min-width: 601px)
{
	.MuiInputLabel-shrink {
		font-size: 17px!important;
		letter-spacing: -.00020em!important;
	}
	.MuiFormLabel-root
	{
		color:#000!important;
		font-size: 17px!important;
	}
}

button:hover, .hoverbtn:hover{
	background: #d31e4d;
	border-color: #d31e4d;
	color: #ffff;
}
.buttonInactive:hover{
	background-color: #d1d1d1!important;
}
button:focus
{
	outline: 5px auto #fff!important;
}
.validInputSaving
{
	background: url(../img/anacoach/corect.png) 95% 50% no-repeat transparent!important;
	background-size: 20px!important;
}
.invalidInputSaving
{
	background: url(../img/anacoach/incorect.png) 95% 40% no-repeat transparent!important;
	background-size: 20px!important;
}

.validInput
{
	background: url(../img/anacoach/corect.png) 99.9% 70% no-repeat transparent!important;
	background-size: 20px!important;
}
.invalidInput
{
	background: url(../img/anacoach/incorect.png) 99.9% 40% no-repeat transparent!important;
	background-size: 20px!important;
}

.validInputWL {
	background: url(../img/WaitlistKanda/True.svg) 97.9% 46% no-repeat transparent!important;
	background-size: 32px!important;
	width: 100%;
	height: 64px;
	border-radius: 16px;
	border: 1px solid rgba(7, 11, 17, 0.2);
	padding: 20px 22px;
}

.invalidInputWL {
	background: url(../img/WaitlistKanda/False.svg) 97.9% 46% no-repeat transparent!important;
	background-size: 32px!important;
	width: 100%;
	height: 64px;
	border-radius: 16px;
	border: 1px solid rgba(7, 11, 17, 0.2);
	padding: 20px 22px;
}

.validSelect
{
	background: url(../img/anacoach/corect.png) 86% 70% no-repeat transparent!important;
	background-size: 19px!important;
}
.invalidSelect
{
	background: url(../img/anacoach/incorect.png) 86% 40% no-repeat transparent!important;
	background-size: 20px!important;
}

.perfil.invalidInput
{
    background: url(../img/anacoach/incorect.png) 90% 50% no-repeat transparent!important;
	background-size: 20px!important;
}

.perfil.validInput
{
	background: url(../img/anacoach/corect.png) 90% 50% no-repeat transparent!important;
	background-size: 20px!important;
}

.perfil.validSelect
{
	background: url(../img/anacoach/corect.png) 90% 0% no-repeat transparent!important;
	background-size: 19px!important;
}
.perfil.invalidSelect
{
	background: url(../img/anacoach/incorect.png) 90% 0% no-repeat transparent!important;
	background-size: 20px!important;
}

.perfil.validSelect2
{
	background: url(../img/anacoach/corect.png) 85% 0% no-repeat transparent!important;
	background-size: 19px!important;
}
.perfil.invalidSelect2
{
	background: url(../img/anacoach/incorect.png) 85% 0% no-repeat transparent!important;
	background-size: 20px!important;
} 

.perfil.genero.validSelect
{
	background: url(../img/anacoach/corect.png) 80% 0% no-repeat transparent!important;
	background-size: 19px!important;
}
.perfil.genero.invalidSelect
{
	background: url(../img/anacoach/incorect.png) 80% 0% no-repeat transparent!important;
	background-size: 20px!important;
}


.perfil.fecha.validSelect
{
	background: url(../img/anacoach/corect.png) 75% 0% no-repeat transparent!important;
	background-size: 19px!important;
}
.perfil.fecha.invalidSelect
{
	background: url(../img/anacoach/incorect.png) 75% 0% no-repeat transparent!important;
	background-size: 20px!important;
}

.Emergency.validSelect
{
	background: url(../img/anacoach/corect.png) 90% 0% no-repeat transparent!important;
	background-size: 19px!important;
}
.Emergency.invalidSelect
{
	background: url(../img/anacoach/incorect.png) 90% 0% no-repeat transparent!important;
	background-size: 20px!important;
}

.validSelectCatalogo
{
	background: url(../img/anacoach/corect.png) 97% 90% no-repeat transparent!important;
	background-size: 19px!important;
}

.MuiMenu-paper
{
	min-width: 30%!important;
}

@media (max-width: 800px)
{
	.MuiMenu-paper
	{
		min-width: 85%!important;
	}
}

.backgroundTheOne
{
	background: url(../img/know_us/the_one.png);
	background-position: center;
	background-attachment: contain;
	background-size: contain;
	background-repeat: no-repeat; 
}

@media (min-width: 800px) {
	.validSelect
	{
		background: url(../img/anacoach/corect.png) 92% 70% no-repeat transparent!important;
		background-size: 19px!important;
	}
	.validSelectCatalogo
	{
		background: url(../img/anacoach/corect.png) 97% 90% no-repeat transparent!important;
		background-size: 19px!important;
	}
}

.textHeader{
	text-decoration: none;
}

.MuiFormGroup-root
{
	width: 90%;
	margin: auto;
}

#showPassword
{
	font-size: 50px!important;
	text-align: center!important;
}

@media (min-width: 800px) {

	.marginLeft
	{
		margin-left: 10%;
	}
}

@media (min-width: 769px)
{
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 1rem;
		flex-flow: row nowrap;
		padding-left: 1rem;
	}
}
@media (min-width: 769px)
{
.navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
}
}
@media(min-width: 2000px){
	.validSelectCatalogo
	{
		/* background: url(/static/media/corect.a72e8dd0.png) 97% 76% no-repeat transparent!important; */
		background-size: 12px!important;
	}

}

.btn-outline-success {
	color:rgb(166, 166, 166)!important;
    border-color:rgb(166, 166, 166)!important;
}

.rowMargin0
{
	margin-right: 0px!important; 
    margin-left: 0px!important;
}



@media(min-width: 700px){
	.ModalCompartir
	{
		position: absolute;
		width: 600px;
		text-align: center;
		border-radius: 16px ;
		overflow: auto;
		overflow-x: hidden;
		height: 397px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 36px 21px;
		box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
		background-color: #fff;
	}

}

@media(max-width: 700px){
	.ModalCompartir
	{
		position: absolute;
		width: 100%;
		text-align: center;
		border-top-right-radius: 40px;
		border-top-left-radius: 40px;
		overflow: auto;
		overflow-x: hidden;
		height: auto;
		top: auto;
		left: 50%;
		bottom: -0%!important;
		transform: translate(-50%, 0%);
		padding: 36px 21px;
		border-radius: 16px;
		box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
		background-color: #fff;
	}

}


