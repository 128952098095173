.bg-dark-coru {
    background: #3f4d67;
}

.logo {
    fill: white;
}

p {
    font-size: 12px;
    margin-bottom: 0.5rem;
}

td {
    font-size: 12px; 
}

.enlaceXcoach {
    text-decoration: underline;
    color: blue;
}

.enlaceXcoach:hover {
    color: blue !important;
}

.button-simple {
    background: none;
    border: none;
}

.button-simple span {
    float: left;
    height: 22px;
    width: 22px;
    font-weight: bold;
    color: white;
    background-color: #3f4d67;
    margin-right: 10px;
    border-radius: 3px;
}

.button-simple span:hover {
    font-weight: none;
}

.button-simple:hover {
    color: black;
    background: none;
    border: none;
    text-decoration-line: underline;
    font-weight: bold;
}

.localized-users {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.header-localized:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.header-localized {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.training-list {
    position: fixed;
    /* left: 5px; */
    top: 95px;
}

.training-item {
    cursor: pointer;
    margin-top: 10px;
    padding: 5px;
}

.training-item-text {
    font-size: 12px;
    color: white;
}

.bg-selected {
    width: 100%;
    height: 100%;
    background-color: #faa82e;
}

.bg-normal {
    background-color: #b7b4b4;
}

.container-typing {
    background-color: #C4C4C4;
    height: 400px;
}

.vertical-center {
    margin: 0;
    position: relative;
    text-align: center;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.image-upload {
    margin-top: 1.5rem;
}

.image-upload > input {
    display: none;
}

.image-upload > img {
    width: 40px;
}

.scrollBox {
    background:#ffff
}