body {
    margin: 0;
    background-color: #e5e5e5;
  }
  
  main {
    width: min(1200px, 90vw);
    margin: auto;
  }
  
  .slider {
    width: 100%;
    height: var(--height);
    overflow: hidden;
    mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
  }
  
  .slider .list {
    display: flex;
    width: 100%;
    min-width: calc(var(--width) * var(--quantity));
    position: relative;
  }

  .list {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    gap: 100px;
  }
  
  .slider .list .item {
    width: var(--width);
    height: var(--height);
    position: absolute;
    left: 100%;
    animation: autoRun var(--velocity) linear infinite;
    transition: filter 0.5s;
    animation-delay: calc((var(--velocity) / var(--quantity)) * (var(--position) - 1) - var(--velocity)) !important;
  }

  .item {
    display: flex;
    align-items: center;
  }
  
  .slider .list .item img {
    width: 100%;
    height: 40px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto 0;
  }
  
  @keyframes autoRun {
    from {
      left: 100%;
    }
    to {
      left: calc(var(--width) * -1);
    }
  }
  
  .slider:hover .item {
    animation-play-state: paused !important;
    filter: grayscale(1);
  }
  
  .slider .item:hover {
    filter: grayscale(0);
  }
  
  .slider[reverse="true"] .item {
    animation: reversePlay 10s linear infinite;
  }
  
  @keyframes reversePlay {
    from {
      left: calc(var(--width) * -1);
    }
    to {
      left: 100%;
    }
  }
  